import React, { useContext, useEffect, useState } from 'react';
import { T } from '../utils/translate/translator';
import { allowedChars } from '../utils/allowedChars';
import moment from 'moment';
import EventListener from 'react-event-listener';

import { UserContext } from '../context/UserContext';
import { LayoutContext } from '../context/LayoutContext';

//Components
import ScanTrolley from '../components/d1Picking/ScanTrolley';
import ScanPosition from '../components/common/ScanPosition';
import ScanUsn from '../components/common/ScanUsn';

//Models and enums
import { ApiFunctions } from '../models/enums/ApiFunctions';
import TrolleyInfo, { InfoModel } from '../models/TrolleyInfo';
import TrolleyInfoRequest from '../models/requests/TrolleyInfoRequest';
import LinkTrolleyInfo from '../models/requests/LinkTrolleyInfoRequest';
import UpdateTrolleyStatusRequest from '../models/requests/UpdateTrolleyStatusRequest';

import { apiService } from '../utils/ApiService';

export default function LinkTrolleyAndUsn() {
    const [activeStep, setActiveStep] = useState(0);
    const [deactivateListener, setDeactivateListener] = useState(false);
    const [trolleyId, setTrolleyId] = useState("");
    const [usn, setUsn] = useState("");
    const [position, setPosition] = useState("");
    const [data, setData] = useState([] as TrolleyInfo[]);
    const [dataIndex, setDataIndex] = useState(0);
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    useEffect(() => {
        layout.setTitle("Link trolley & USN");

        // eslint-disable-next-line
    }, []);

    const keyboardListener = (e: KeyboardEvent) => {
        if (layout.isLoading)
            return;

        if (e.key.match(allowedChars) && !deactivateListener) {
            if (activeStep === 0)
                return setTrolleyId((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 1)
                return setPosition((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 2)
                return setUsn((prevState) => prevState + e.key.toUpperCase());
        }

        //Akce, které se budou v různých krocích provádět na Enter
        if (e.key === 'Enter') {
            if (activeStep === 0 && trolleyId)
                return loadData();
            else if (activeStep === 1 && position)
                return verifyScannedPosition();
            else if (activeStep === 2 && usn)
                return trolleyLinkage();
        }
    }

    //Handlers
    const handleNext = () => { setActiveStep(activeStep + 1); setDeactivateListener(false) };

    const handleBack = () => {
        if (data.length - 1 === dataIndex) {
            setActiveStep(activeStep - 1);
            setDeactivateListener(false);
        }
        else {
            setDataIndex(dataIndex - 1);
            setDeactivateListener(false);
        }
    };

    const handleReset = () => {
        setActiveStep(0);
        setDeactivateListener(false);
        layout.success("OK");
        setUsn("");
        setTrolleyId("");
        setPosition("");
        setData([] as TrolleyInfo[]);
        setDataIndex(0);
        layout.setIsLoading(false);
    };

    const handleBackFromUsn = () => {
        setActiveStep(activeStep - 1);
        setDeactivateListener(false);
        setUsn("");
        setPosition("");
    };

    const loadData = () => {
        layout.setIsLoading(true);

        const requestData: TrolleyInfoRequest = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            TROLLEY: trolleyId
        }

        apiService.post("", ApiFunctions.GetTrolleyInfo, requestData, {
            success: (data: TrolleyInfo[]) => {
                findOpenTrolleys(data);
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                //setTrolleyId("");
            }
        });
    }

    const findOpenTrolleys = (responseData: TrolleyInfo[]) => {
        //Najdu si pouze otevřené vozíky, pokud není ani jeden vypíšu error hlášku.
        const openTrolleys: TrolleyInfo[] = responseData.filter(trolley => trolley.STATUS === "0");
        setData(openTrolleys);
        layout.setIsLoading(false);

        if (openTrolleys.length > 0)
            handleNext();
        else
            layout.error(T("NoTrolleyOpen"));
    }

    const verifyScannedPosition = () => {
        //Ověřím, jestli naskenovaný vozík odpovídá tomu, na kterém se aplikace aktuálně nachází.
        if (position.slice(0, -2) !== data[dataIndex].TROLLEY) {
            layout.error(T("NotTheRightTrolley"));
            return setPosition("");
        }

        const isPositionAlreadyInTrolley = data[dataIndex].INFO.filter(i => i.POSITION === position).length > 0
        if (isPositionAlreadyInTrolley) {
            layout.error(T("PositionError1/2") + position + T("PositionError2/2"));
            return setPosition("");
        }

        handleNext();
    }

    const trolleyLinkage = () => {
        layout.setIsLoading(true);

        const requestData: LinkTrolleyInfo = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            TROLLEY: trolleyId,
            POSITION: position,
            USN: usn
        }

        apiService.post("", ApiFunctions.LinkTrolleyInfo, requestData, {
            success: () => {
                const addedUsnToPosition: InfoModel = { POSITION: position, USN: usn }
                data[dataIndex].INFO.push(addedUsnToPosition);
                layout.success("Ok");
                layout.setIsLoading(false);
                setActiveStep(1);
                setUsn("");
                setPosition("");
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                //setActiveStep(1);
                //setUsn("");
                //setPosition("");
            }
        });
    }

    const nextTrolleyOrFinish = () => {
        if (data.length - 1 === dataIndex) {
            layout.setIsLoading(true);

            const requestData: UpdateTrolleyStatusRequest = {
                REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
                USERID: user.user.employeeId,
                TROLLEY: trolleyId,
                STATUS: 1
            }

            apiService.post("", ApiFunctions.UpdateTrolleyStatus, requestData, {
                success: () => {
                    handleReset();
                },
                error: (message: string) => {
                    layout.error(message);
                    layout.setIsLoading(false);
                    //setActiveStep(1);
                    //setUsn("");
                    //setPosition("");
                }
            });
        }
        else
            setDataIndex(dataIndex + 1);
    }

    return (
        <EventListener target="document" onKeyUp={keyboardListener}>
            {activeStep === 0 &&
                <ScanTrolley trolleyId={trolleyId} setTrolleyId={setTrolleyId} deactivateListener={setDeactivateListener} handleNext={loadData} />
            }

            {activeStep === 1 &&
                <ScanPosition data={data[dataIndex].INFO} trolleyId={data[dataIndex].TROLLEY} position={position} setPosition={setPosition} nextDisabled={false}
                    isFinish={data.length - 1 === dataIndex} deactivateListener={setDeactivateListener} handleNext={nextTrolleyOrFinish} handleBack={handleBack} />
            }

            {activeStep === 2 &&
                <ScanUsn usn={usn} setUsn={setUsn} deactivateListener={setDeactivateListener} handleNext={trolleyLinkage} backEnabled handleBack={handleBackFromUsn} />
            }
        </EventListener>
    );
}