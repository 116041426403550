import React, { useContext, useEffect, useState } from 'react';
import { T } from '../utils/translate/translator';
import { allowedChars } from '../utils/allowedChars';
import moment from 'moment';
import EventListener from 'react-event-listener';

import { UserContext } from '../context/UserContext';
import { LayoutContext } from '../context/LayoutContext';

//Components
import ScanTrolley from '../components/d1Picking/ScanTrolley';
import ScanPosition from '../components/common/ScanPosition';

//Models and enums
import { ApiFunctions } from '../models/enums/ApiFunctions';
import TrolleyInfo from '../models/TrolleyInfo';

import { apiService } from '../utils/ApiService';

export default function LinkTrolleyAndUsn() {
    const [activeStep, setActiveStep] = useState(0);
    const [deactivateListener, setDeactivateListener] = useState(false);
    const [trolleyId, setTrolleyId] = useState("");
    const [data, setData] = useState([] as TrolleyInfo[]);
    const [dataIndex, setDataIndex] = useState(0);
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    useEffect(() => {
        layout.setTitle(T("TrolleyInfo"));

        // eslint-disable-next-line
    }, []);

    const keyboardListener = (e: KeyboardEvent) => {
        if (layout.isLoading)
            return;

        if (e.key.match(allowedChars) && !deactivateListener) {
            if (activeStep === 0)
                return setTrolleyId((prevState) => prevState + e.key.toUpperCase());
        }

        //Akce, které se budou v různých krocích provádět na Enter
        if (e.key === 'Enter') {
            if (activeStep === 0 && trolleyId)
                return loadData();
        }
    }

    //Handlers
    const handleNext = () => { setActiveStep(activeStep + 1); setDeactivateListener(false) };

    const handleBack = () => {
        if (data.length - 1 === dataIndex) {
            setActiveStep(activeStep - 1);
            setDeactivateListener(false);
        }
        else {
            setDataIndex(dataIndex - 1);
            setDeactivateListener(false);
        }
    }

    const handleReset = () => {
        setActiveStep(0);
        setDeactivateListener(false);
        setTrolleyId("");
        setData([] as TrolleyInfo[]);
        setDataIndex(0);
        layout.setIsLoading(false);
    }

    const loadData = () => {
        layout.setIsLoading(true);
        const requestData = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            TROLLEY: trolleyId
        }

        apiService.post("", ApiFunctions.GetTrolleyInfo, requestData, {
            success: (data: TrolleyInfo[]) => {
                setData(data);
                layout.setIsLoading(false);
                handleNext();
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                //setTrolleyId("");
            }
        });
    }

    const nextTrolleyOrFinish = () => {
        if (data.length - 1 === dataIndex)
            handleReset();
        else
            setDataIndex(dataIndex + 1);
    }

    return (
        <EventListener target="document" onKeyUp={keyboardListener}>
            {activeStep === 0 &&
                <ScanTrolley trolleyId={trolleyId} setTrolleyId={setTrolleyId} deactivateListener={setDeactivateListener} handleNext={loadData} />
            }

            {activeStep === 1 &&
                <ScanPosition data={data[dataIndex].INFO} trolleyId={data[dataIndex].TROLLEY} position="" setPosition={() => { }} nextDisabled={false}
                    isFinish={data.length - 1 === dataIndex} deactivateListener={setDeactivateListener} handleNext={nextTrolleyOrFinish} handleBack={handleBack} hidePosition />
            }
        </EventListener>
    );
}
