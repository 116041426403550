import React, { useContext, useEffect, useState } from 'react';
import { T } from '../utils/translate/translator';
import { allowedChars } from '../utils/allowedChars';
import moment from 'moment';
import EventListener from 'react-event-listener';

import { UserContext } from '../context/UserContext';
import { LayoutContext } from '../context/LayoutContext';

//Components
import ScanTrolley from '../components/d1Picking/ScanTrolley';
import BinInformation from '../components/common/BinInformation';
import ScanPosition from '../components/d1Picking/ScanPosition';

//Models and enums
import { SapDayNumbers } from '../models/enums/SapDayNumbers';
import { ApiFunctions } from '../models/enums/ApiFunctions';
import PickingInfoRequest from '../models/requests/PickingInfoRequest';
import DeductMaterialRequest from '../models/requests/DeductMaterialRequest';
import { InfoModel, PickingInfo } from '../models/PickingInfo';

import { apiService } from '../utils/ApiService';

export default function D1Picking() {
    const [activeStep, setActiveStep] = useState(0);
    const [deactivateListener, setDeactivateListener] = useState(false);
    const [trolleyId, setTrolleyId] = useState("");
    const [bin, setBin] = useState("");
    const [position, setPosition] = useState("");
    const [data, setData] = useState([] as PickingInfo[]);
    const [dataIndex, setDataIndex] = useState(0);
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    useEffect(() => {
        layout.setTitle("D1 Picking");

        // eslint-disable-next-line
    }, []);

    const keyboardListener = (e: KeyboardEvent) => {
        if (layout.isLoading)
            return;

        if (e.key.match(allowedChars) && !deactivateListener) {
            if (activeStep === 0)
                return setTrolleyId((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 1)
                return setBin((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 2)
                return setPosition((prevState) => prevState + e.key.toUpperCase());
        }

        //Akce, které se budou v různých krocích provádět na Enter
        if (e.key === 'Enter') {
            if (activeStep === 0 && trolleyId)
                return loadData();
            else if (activeStep === 1 && bin)
                return verifyBin();
            else if (activeStep === 2 && position)
                return deductQuantity();
        }
    }

    //Handlers
    const handleNext = () => { setActiveStep(activeStep + 1); setDeactivateListener(false) };
    const handleBack = () => { setActiveStep(activeStep - 1); setDeactivateListener(false) };
    const handleReset = () => {
        setActiveStep(0);
        setDeactivateListener(false);
        layout.success("OK");
        setBin("");
        setTrolleyId("");
        setPosition("");
        setData([] as PickingInfo[]);
        setDataIndex(0);
        layout.setIsLoading(false);
    }

    const loadData = () => {
        layout.setIsLoading(true);

        const requestData: PickingInfoRequest = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            TROLLEY: trolleyId,
            POSITION: "",
            USN: "",
            DAYN: SapDayNumbers.Day1
        }

        apiService.post("", ApiFunctions.GetPickingData, requestData, {
            success: (data: PickingInfo[]) => {
                layout.setIsLoading(false);
                setData(data);
                handleNext();
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                //setTrolleyId("");
            }
        });
    }

    const verifyBin = () => {
        if (bin === data[dataIndex].BIN)
            handleNext();
        else {
            layout.error(T("BinError1/2") + bin + T("BinError2/2") + " " + data[dataIndex].BIN);
            setBin("");
        }
    }

    const deductQuantity = () => {
        let trolley: PickingInfo = data[dataIndex];
        let item: InfoModel | undefined = data[dataIndex].INFO.find(i => i.POSITION === position.toUpperCase());

        if (item && !item.isScanned) {
            item.isScanned = true;
            trolley.TOTALQTY -= item.QTY;

            layout.setIsLoading(true);

            const requestData: DeductMaterialRequest = {
                REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
                USERID: user.user.employeeId,
                TROLLEY: trolleyId,
                USN: "",
                POSITION: position,
                DAYN: SapDayNumbers.Day1,
                BIN: bin,
                QTY: item.QTY
            }

            apiService.post("", ApiFunctions.DeductMaterial, requestData, {
                success: () => {
                    layout.setIsLoading(false);
                    setPosition("");
                    if (trolley.INFO.find(({ POSITION, isScanned }) => !isScanned && POSITION.startsWith(trolleyId)) === undefined)
                        nextBinOrFinish();
                },
                error: (message: string) => {
                    layout.error(T("SfcsDeductError") + " " + message);
                    layout.setIsLoading(false);
                    //setPosition("");
                }
            });
        } else {
            layout.error(T("PositionError1/2") + position + T("PositionError2/2"));
            setPosition("");
        }
    }

    const nextBinOrFinish = () => {
        if (data.length - 1 === dataIndex)
            handleReset();
        else {
            setDataIndex(dataIndex + 1);
            setActiveStep(1);
            setBin("");
        }
    }

    return (
        <EventListener target="document" onKeyUp={keyboardListener}>
            {activeStep === 0 &&
                <ScanTrolley trolleyId={trolleyId} setTrolleyId={setTrolleyId} deactivateListener={setDeactivateListener} handleNext={loadData} />
            }

            {activeStep === 1 &&
                <BinInformation data={data[dataIndex]} bin={bin} setBin={setBin} deactivateListener={setDeactivateListener} handleNext={verifyBin} handleBack={handleBack}>
                    <b>{T("Trolley")}: </b>{trolleyId}
                </BinInformation>
            }

            {activeStep === 2 &&
                <ScanPosition data={data[dataIndex]} trolleyId={trolleyId} position={position} setPosition={setPosition} nextDisabled={data[dataIndex].INFO.find(({ POSITION, isScanned }) => isScanned !== true && POSITION.startsWith(trolleyId)) !== undefined}
                    isFinish={data.length - 1 === dataIndex} deactivateListener={setDeactivateListener} handleNext={nextBinOrFinish} handleBack={handleBack} />
            }
        </EventListener>
    );
}