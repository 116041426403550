import { getApiToken } from "../context/UserContext";
import { ApiResponseStatus } from "../models/enums/ApiResponseStatus";
import { apiUrl } from "./BaseUrl";
import { vibrate } from "./Helpers";

interface FetchOptions {
    success: (data: any) => void,
    error: (message: string) => void
}

class ApiService {
    private _apiUrl: string = '';

    public Init(apiUrl: string) {
        this._apiUrl = apiUrl;
    }

    public async get(url: string, options: FetchOptions) {

        const initReq: RequestInit = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            }
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async post(url: string, dynamicId: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'post',
            credentials: 'include',
            headers: {
                'Dynamic-ID': dynamicId,
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async put(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'put',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async delete(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'delete',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }
}

function basicFetch(
    url: string,
    options: FetchOptions,
    initReq: RequestInit,
    retries: number = 3,
    delay: number = 3000
) {
    return new Promise((resolve, reject) => {
        const attemptFetch = async (retryCount: number) => {
            try {
                const res = await fetch(url, initReq);
                if (res.ok) {
                    const result = await res.json();
                    if (result && result.Status === ApiResponseStatus.Ok) {
                        options.success(result.Data);
                        resolve(result.Data);
                    } else {
                        vibrate([200, 100, 200]);
                        options.error(result.Message);
                        resolve(result.Message);
                    }
                } else {
                    const result = await res.json();
                    vibrate([200, 100, 200]);
                    const title = result.title ?? "";
                    const message = result.message ?? "";
                    const errorMessage = `${title}${message} [${res.status}]`;

                    if (retryCount > 0) {
                        setTimeout(() => attemptFetch(retryCount - 1), delay);
                    } else {
                        options.error(errorMessage);
                        resolve(errorMessage);
                    }
                }
            } catch (error: any) {
                vibrate([200, 100, 200]);
                if (retryCount > 0) {
                    setTimeout(() => attemptFetch(retryCount - 1), delay);
                } else {
                    options.error(error.toString());
                    reject(error.toString());
                }
            }
        };

        attemptFetch(retries);
    });
}

const apiService: ApiService = new ApiService();

apiService.Init(apiUrl);

export { apiService };
